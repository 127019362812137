enum ProductDetails {
  BF = "per board feet",
  BG = "per bag",
  BX = "per box",
  CT = "per carton",
  DY = "per day",
  EA = "each",
  F2 = "per square foot",
  F3 = "per cubic foot",
  FI = "per fifty",
  FV = "per five",
  HD = "per hundred",
  HF = "per hundred feet",
  HM = "per hundred metres",
  HR = "per hour",
  KG = "per kilo",
  LM = "per linear metre",
  LN = "per length",
  M2 = "per square metre",
  M3 = "per cubic metre",
  MI = "per minute",
  MT = "per metre",
  PC = "per piece",
  PK = "per pack",
  Pk = "per pack (approx)",
  PR = "per pair",
  PT = "per pallet",
  RL = "per roll",
  RW = "per rows",
  SE = "per set",
  SH = "per sheet",
  ST = "per standard (timber) measure",
  SV = "per seven",
  T = "per tonne",
  T2 = "per 10 square metres",
  TB = "per tonne bag - reusable",
  TH = "per thousand",
  TM = "per ten metres",
  TN = "per ten",
  TP = "per ten pairs",
  TU = "per tub",
  TW = "per twenty",
  WK = "per week",
}

enum ProductFilters {
  board_type = "Board Type",
  categories = "Categories",
  category = "Category",
  sizes = "Sizes",
  brand = "Brand",
  colors = "Colours",
  availability_flag = "Availability Flag",
  large_facet_expand = "See all",
  large_facet_contract = "- See less",
  cf_project_pack = "Project Pack Included?",
  availability_reason = "Availability Reason",
  cf_brand = "Brand",
  cf_category = "Category",
  cf_application = "Application",
  cf_bristle_material = "Bristle Material",
  cf_capacity = "Capacity",
  cf_cavity = "Cavity",
  cf_colour = "Colour",
  cf_colour_range = "Colour Range",
  cf_compressive_strength = "Compressive Strength",
  cf_configuration = "Configuration",
  cf_connection_type = "Connection Type",
  cf_control_method = "Control Method",
  cf_coverage = "Coverage",
  cf_depth = "Depth",
  cf_diameter = "Diameter",
  cf_drum_speed = "Drum Speed",
  cf_edge = "Edge",
  cf_finish = "Finish",
  cf_fitment_shape = "Fitment Shape",
  cf_fitting = "Fitting",
  cf_fitting_type = "Fitting Type",
  cf_gauge = "Gauge",
  cf_glass_thickness = "Glass Thickness",
  cf_grade = "Grade",
  cf_height = "Height",
  cf_length = "Length",
  cf_material = "Material",
  cf_max_flow_rate = "Max Flow Rate",
  cf_model = "Model",
  cf_nominal_cavity_width = "Nominal Cavity Width",
  cf_no_of_pieces_in_complete_pack = "No. of Items in Pack",
  cf_no_of_teeth = "No. of Teeth",
  cf_opening = "Opening",
  cf_operation = "Operation",
  cf_output = "Output",
  cf_packaging = "Packaging",
  cf_packaging_type = "Packaging Type",
  cf_pack_quantity = "Pack Quantity",
  cf_pack_size = "Pack Size",
  cf_pile_length = "Pile Length",
  cf_pressure = "Pressure",
  cf_product_code = "Product Code",
  cf_product_type = "Product Type",
  cf_protype = "Protype",
  cf_range = "Range",
  cf_rating = "Rating",
  cf_shape = "Shape",
  cf_size = "Size",
  cf_style = "Style",
  cf_suitable_water_pressure = "Suitable Water Pressure",
  cf_surface_finish = "Surface Finish",
  cf_system = "System",
  cf_tapholes = "Tapholes",
  cf_temperature = "Temperature",
  cf_texture = "Texture",
  cf_thickness = "Thickness",
  cf_treatment = "Treatment",
  cf_type = "Type",
  cf_voltage = "Voltage",
  cf_wattage = "Wattage",
  cf_weight = "Weight",
  cf_width = "Width",
  color_groups = "Colour Groups",
  gender = "Gender",
  is_tally = "Is Tally",
  meta_description = "Meta Description",
  offer_price = "Offer Price",
  offer_price_inc_vat = "Offer Price inc. Vat",
  offer_unit = "Offer Unit",
  page_title = "Page Title",
  pid = "Pid",
  price = "Price",
  price_inc_vat = "Price inc. Vat",
  price_range = "Price Range",
  sale_price = "Sale Price",
  unit = "Unit",
  cf_no_of_columns = "No. of Columns",
  cf_connection_size = "Connection size",
  cf_btu_rating = "BTU Rating",
  cf_orientation = "Orientation",
  cf_drive_type = "Drive Type",
  cf_head_type = "Head Type",
  cf_bolt_size = "Bolt Size",
  cf_treated = "Treated",
  cf_planed = "Planed",
  cf_sawn = "Sawn",
}

export type ProductDetailsType = keyof typeof ProductDetails;
export type ProductFiltersType = keyof typeof ProductFilters;

export function productUnitTranslation(key: ProductDetailsType): string | undefined {
  return ProductDetails[key];
}

export function productFiltersTranslation(key: ProductFiltersType): string | undefined {
  return ProductFilters[key];
}
